<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-cascader
          @change="changeArea"
          placeholder="请选择代理地区"
          :options="areasList"
          :props="{ value: 'name', label: 'name', checkStrictly: true }"
          filterable
          clearable
        ></el-cascader>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="query.type"
          placeholder="请选择代理商类型"
          :maxLength="50"
          @change="getList(1)"
          clearable
        >
          <el-option
            v-for="(item, i) in this.const.AGENT_LEVEL"
            :value="item.value"
            :label="item.name"
            :key="i"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-input
          v-model="query.operationName"
          placeholder="代理商名称"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>
    <div>
      <el-button class="but" type="primary" @click="showAddAgent"
       v-auth="this.per.AGENT_ADD"
        >新增代理商</el-button
      >
    </div>
    
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        label="代理商名称"
        prop="operationName" 
      ></el-table-column>

      <el-table-column label="地址" prop="address"></el-table-column>

      <el-table-column label="充值比例" prop="chargeScale">
        <template #default="scope">
          <span>{{ scope.row.chargeScale }}</span>
        </template>
      </el-table-column>

      <el-table-column label="用户押金" prop="depositScale">
        <template #default="scope">
          <span>{{ scope.row.depositScale }}</span>
        </template>
      </el-table-column>

      <el-table-column label="联系方式" prop="phone"></el-table-column>

      <el-table-column label="押金" prop="deposit">
        <template #default="scope">
          <span>{{ scope.row.deposit / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="已冻结押金" prop="freezeDeposit">
        <template #default="scope">
          <span>{{ scope.row.freezeDeposit / 100 }}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="操作">
        <template #default="scope">
          <span class="option option-primary" @click="showEditAgent(scope.row)"  v-auth="this.per.AGENT_UPDATA"
            >编辑</span
          >

          <span class="option option-primary" @click="goAssets(scope.row)"  v-auth="this.per.AGENT_UPDATA"
            >资产详情</span
          >

          <span class="option option-danger" @click="doDelAgent(scope.row)"  v-auth="this.per.AGENT_DEL"
            >删除</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :agentList="agentList"
      @submit="submitAgent"
      :ref="MODAL_KEY.EDIT_MODAL"
    ></EditModal>
  </div>
</template>

<script>
import { fetchAgentList, addAgent, updateAgent ,delAgent} from "@/api/agent";
import EditModal from "./component/EditModal";
import { areas } from "@/common/area.js";
import { mapState } from "vuex";

export default {
  components: { EditModal },
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    return {
      areasList: areas,
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {},
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    // 代理商资产详情
    goAssets(data){
      this.$router.push(`/agent/asset-detail/${data.id}/${data.phone}`);
    },

    doDelAgent(row){
      this.$confirm("是否要删除代理商", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        delAgent({ id: row.id }).then((res) => {
          this.$message.success("删除成功");
          this.getList();
        });
      });
    },
    // 修改查询条件
    changeArea(e) {
      if (!this.validatenull(e)) {
        this.query.province = e[0];
        this.query.city = e[1] || "";
        this.query.area = e[2] || "";
      } else {
        this.query.province = "";
        this.query.city = "";
        this.query.area = "";
      }
      this.getList(1);
    },

    showAddAgent() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    showEditAgent(data) {
      this.modalData = this.deepClone(data);
      this.modalData.depositScale = this.modalData.depositScale;
      this.modalData.collectionMoney = this.modalData.collectionMoney && this.modalData.collectionMoney / 100;
      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    submitAgent(data) {
      let request = updateAgent;
      let msg = "编辑代理商成功";
      if (this.validatenull(data.id)) {
        request = addAgent;
        msg = "添加代理商成功";
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.initData();
      });
    },

    getList(current) {
      console.log(current, "current");
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchAgentList({ query: this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },

    initData() {
      this.$store.dispatch("fetchAgentListAll");
      this.getList();
    },
  },

  mounted() {
    this.initData();
  },
};
</script>
